import React, { useState, useEffect } from "react";
import { useEventID } from "wecall-config-lib";
import logo from "../assets/logo.png";
//import newslogo1 from "../assets/news-logo01.png";
//import newslogo2 from "../assets/news-logo02.png";
//import newslogo3 from "../assets/news-logo03.png";
//import newslogo4 from "../assets/news-logo04.png";
//import newslogo5 from "../assets/news-logo05.png";
//import newslogo6 from "../assets/news-logo06.png";
import star from "../assets/star.png";
import { Link, Outlet, Route, Routes } from "react-router-dom";
import arrow from "../assets/arrow.svg";
import testimonial from "../assets/trustpilot-logo.png";
import user1 from "../assets/user-1.jpeg";
import user2 from "../assets/user-2.jpeg";
import user3 from "../assets/user-3.jpeg";
import user4 from "../assets/user-4.jpeg";
import InitialScripts from "../script/initialScripts";
import FloatingCard from "../floating-card";
import gasImage from "../assets/gas.webp";
import groceryImage from "../assets/groceries.webp";
import rentImage from "../assets/rent.webp";
import shoppingImage from "../assets/shopping.webp";

export default function DefaultPage({ setNumber, cityStateResponse }) {
  const { state, city, zip } = cityStateResponse;
  return (
    <>
      <InitialScripts setNumber={setNumber} />
      <div className="App">
        <div className="mainWrapper">
          <header>
            <div className="container">
              <div className="row">
                <div className="logo-wrapper">
                  <a href="#">
                    <img src={logo}></img>
                  </a>
                </div>
              </div>
            </div>
          </header>
          <div className="hero-section">
            <div className="container">
              <div className="row">
                <Outlet />
              </div>
            </div>
          </div>
          {
            //
            // <div className="logo-strip-section">
            //  <div className="container">
            //    <div className="row">
            //      <div className="logo-strip-headline">
            //        <h1>MENTIONED ON</h1>
            //      </div>
            //      <div className="logo-strip">
            //        <div className="logoitem">
            //          <img src={newslogo1}></img>
            //          <img src={newslogo2}></img>
            //          <img src={newslogo3}></img>
            //          <img src={newslogo4}></img>
            //          <img src={newslogo5}></img>
            //          <img src={newslogo6}></img>
            //        </div>
            //      </div>
            //    </div>
            //  </div>
            // </div>
            //
          }
          <div className="testimonial">
            <div className="container">
              <div className="row">
                <div className="testimonial-headline">
                  <h1>What Our Customers Are Saying</h1>

                  <img src={testimonial} alt="" />
                </div>
                <div className="testimonial-content-holder">
                  <p>Here's what some of our thousands of customers over the years have to say!</p>
                </div>
                <div className="testimonial-list">
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img src={user1}></img>
                        </div>
                        <div className="testimonial-right">
                          <div className="star-rating">
                            <img src={star} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <h3>Floyd Miles</h3>
                        <p>
                          I was hesitant at first when I heard about the $1400 subsidy card, but I decided to apply anyway. I'm so glad I did. This card has been a real game-changer for me. It's helped me cover the cost of things like rent, utilities, and other expenses that I would normally struggle to pay for. I feel like I can breathe easier now knowing that I have this card to fall back on. It's a great program, and I'm so grateful to have been eligible for it.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img src={user2}></img>
                        </div>
                        <div className="testimonial-right">
                          <div className="star-rating">
                            <img src={star} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <h3>Michael Anderson</h3>
                        <p>
                          I never thought I would qualify for the $1400 subsidy card, but I decided to apply anyway. I was pleasantly surprised to find out that I was eligible, and I've been using the card to help me with all sorts of expenses. It's been especially helpful for things like medical bills and prescription medications, which can be really expensive. I'm so grateful for this program and for the peace of mind that it's given me. It's a great resource for anyone who needs a little extra help making ends meet.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img src={user4}></img>
                        </div>
                        <div className="testimonial-right">
                          <div className="star-rating">
                            <img src={star} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <h3>Olivia Martinez</h3>
                        <p>
                          I couldn't believe my luck when I received the $1400 subsidy card in the mail. As someone who has been struggling to make ends meet, this card has been a lifesaver. I've been able to use it to purchase groceries, pay for gas, and cover other everyday expenses without having to worry about how I'm going to afford them. It's been a huge relief and has made a real difference in my life. Thank you so much for this incredible opportunity.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="container top-footer">
              <div className="row">
                <div className="footer-content">
                  <p>
                    {window.domain_settings.websiteName} is not an insurance or
                    operating company but connects individuals with insurance
                    providers and other affiliates. Plans are insured or covered
                    by a Medicare Advantage organization with a Medicare
                    contract and/or a Medicare-approved Part D
                    sponsor.Enrollment in the plan depends on the plan’s
                    contract renewal with Medicare. Possible options include,
                    but are not limited to Major Medical Plans, Short Term
                    Plans, Christian Health Plans, Health Sharing Plans,
                    discount cards and Fixed Indemnity Plans.Descriptions are
                    for informational purposes only and subject to change.
                    {" " + window.domain_settings.websiteName} is not affiliated
                    with any insurance plan nor does it represent or endorse any
                    plan. We do not offer every plan available in your area. Any
                    information we provide is limited to those plans we do offer
                    in your area. Please contact Medicare.gov or 1–800 MEDICARE
                    to get information on all of your options. By using this
                    site, you acknowledge that you have read and agree to the
                    Terms of Service, and Privacy Policy. Not affiliated with
                    the U. S. government or federal Medicare program. A licensed
                    agent may contact you regarding this insurance-related
                    information.
                  </p>
                </div>
              </div>
            </div>
            <div className="container bottom-footer">
              <div className="row">
                <div className="footer-navigation">
                  <div className="footer-copyright">
                    <p>© Copyright {window.domain_settings.websiteTitle} 2023</p>
                  </div>
                  <div className="footer-navigation-wrapper">
                    <ul>
                      <li>
                        <a href="/privacy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/terms">Terms of Use</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FloatingCard />
      <LanderSocialEventTrigger />
    </>
  );
}

function LanderSocialEventTrigger() {
  const { getEventId } = useEventID();

  const viewContentKey = "PageView";
  const viewContentKey2 = "PageView2";

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  useEffect(() => {
    if (window.fbcFunc) {
      window.fbcFunc("track", "PageView", { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey, "true");
    }

    if (window.tikTokEvent && params.get("utm_source") === "tiktok") {
      window.tikTokEvent.track("ViewContent", {}, { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey2, "true");
    }
  }, []);
  return <></>;
}
